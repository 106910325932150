import React from "react"
import SEO from "../components/seo"
import InfoContent from '../partials/info';

const Info = ({transitionStatus}) => (
  <>
    <SEO title="Info" />
    <InfoContent transitionStatus={transitionStatus} />
  </>
)

export default Info;
