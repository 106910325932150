import React, {useEffect, useRef} from "react";
import gsap from 'gsap';
import ScrollWrapper from '../../components/scrollWrapper';
import Gallery from '../../components/gallery';
import Parallax from '../../components/parallax';
import ScrollReveal from '../../components/scrollReveal';
import {isLoaded, clamp} from '../../utils';

const Wrapper = ({children}) => {
    return (
        <div className="fixed top-0 left-0 w-full h-full hidden lg:block">{children}</div>
    )
}
const Sections = ({children,forwardedRef}) => {
    return (
        <div ref={forwardedRef} className="bg-black flex flex-no-wrap">{children}</div>
    )
}

const Section = ({children, className}) => {
    return (
    <div className={`flex-shrink-0 h-screen ${className}`}>{children}</div>
    )
}

const Paragraphs = ({children, className}) => {
    return (
        <div className={`text-indent text-gray ${className}`}>{children}</div>
    )
}

const Footer = ({forwardedRef, scrollTo}) => {

    const {scrollNudge, footer} = forwardedRef;
    return (
        <footer ref={footer} className="absolute left-0 bottom-0 w-full">
            <div className="container text-xs leading-none flex justify-between items-center py-5">
                <a href="https://www.dropbox.com/sh/m4ycyk8agd91p71/AAA-EGjZp5kM2MA5yNL1x0tua?dl=1" target="blank" className="hover:text-yellow transition duration-500 ease-in-out" cursor="active">Presskit</a>
                <p ref={scrollNudge} onClick={scrollTo}>Scroll to read more <span className="arrow-right inline-block ml-2"></span></p>
            </div>
        </footer>
    )
}

const InfoDesktop = ({transitionStatus}) => {

    const intro = useRef(null);
    const sections = useRef(null);
    const footer = useRef(null);

    const scrollNudge = useRef(null);
    
    useEffect(() => {

        window.addEventListener('onUpdate', onUpdate);
        revealSetup();

        return () => {
            window.removeEventListener('onUpdate', onUpdate);
        }
    },[]);

    useEffect(() => {
    
        if(!isLoaded()) {
            window.addEventListener('loadingDone', revealScene);
        }
        
        if(isLoaded() && transitionStatus == 'entered') {
            revealScene();
        }

        if(transitionStatus == 'exiting') {
            exitScene();
        }
    },[transitionStatus]);

    const onUpdate = (e) => {
        const {scroll} = e.detail;
        gsap.set(scrollNudge.current, {
            opacity: 1 - 1 * clamp((scroll/100), 0, 1)
        });
    }


    const revealSetup = () => {
    
        var words = intro.current.querySelectorAll('[animate-intro-word]');
        var text = intro.current.querySelector('[animate-intro-text]');
        gsap.set(words, {
            opacity:0,
        });

        gsap.set(text.children, {
            opacity:0,
        });

        gsap.set(footer.current, {
            opacity:0,
        });
    
    }
    const revealScene = () => {
        
        var words = intro.current.querySelectorAll('[animate-intro-word]');
        var text = intro.current.querySelector('[animate-intro-text]');

        gsap.fromTo(words,{
            opacity:0,
            yPercent:-75,
            rotationX:45,
        }, {
            opacity:1,
            yPercent:0,
            rotationX:0,
            duration:0.8,
            ease:"circ.out",
            stagger: {
                amount:0.3,
                ease:"power1.inOut",
            }
        });

        gsap.fromTo(text.children,{
            y:60,
            opacity:0,
        }, {
            opacity:1,
            y:0,
            duration:0.8,
            delay:0.2,
            ease:"circ.out",
            stagger: {
                amount:0.1,
                ease:"power1.inOut",
            },
        });

        gsap.to(footer.current, {
            opacity:1,
            delay:1,
        });

        window.removeEventListener('loadingDone', revealScene);
    }

    const exitScene = () => {
    
        gsap.to(sections.current, {
            opacity:0
        });

        gsap.to(footer.current, {
            opacity:0,
        });
    }
    
    const scrollTo = () => {
    
        window.dispatchEvent(new CustomEvent("scrollTo", {
            detail: {
                target: window.innerWidth + 100,
            }
        }));
    }

    return (
        <Wrapper>
            <ScrollWrapper>
                <Sections forwardedRef={sections}>
                    <Section className="w-screen perspective">
                        <div className="container h-screen py-24">
                            <h1 className="text-5xl display:text-7xl w-full h-full leading-none grid-info" ref={intro}>
                                <div className="relative">
                                    <span className="absolute -ml-2 inline-block tracking-normal" animate-intro-word="true">A</span> <Parallax speed="2"><span className="ml-vw3/12 inline-block" animate-intro-word="true">Solo</span></Parallax>
                                </div>
                                <div className="relative">
                                    
                                    <span className="absolute mt-12 text-sm leading-snug" animate-intro-text="true">
                                        <p className="mb-2">Kontrapunkt type</p>
                                        <p className="text-gray">Exhibition in collaboration with <br />kyoto ddd gallery</p>
                                    </span>
                                    <Parallax speed="6">
                                        <span className="ml-vw3/12 inline-block tracking-normal" animate-intro-word="true">Exhibition</span>
                                    </Parallax>
                                </div>

                                <div className="relative">
                                    <span className="absolute -ml-4 inline-block tracking-normal" animate-intro-word="true">By</span>
                                    <span className="ml-vw3/12 inline-block tracking-normal" animate-intro-word="true">Kontrapunkt</span>
                                </div>
                            </h1>
                          
                        </div>
                    </Section>

                    <Section className="w-vw4/12 display:w-vw3/12 ml-48 mr-8 grid-info">
                        <div className="container grid-into-2-row pt-20">
                            <ScrollReveal>
                                <h2 className="absolute -mt-32 text-xl leading-snug is-lang-en">A virtual exhibition is born</h2>
                                <h2 className="absolute -mt-32 text-xl leading-snug is-lang-ja font-ja">バーチャル展示の誕生</h2>
                                <Paragraphs className="is-lang-en">
                                    <p>In 2019 we opened the doors to Kontrapunkt Type at Ginza Graphic Gallery in Tokyo. With more than 150 visitors each day, we were thrilled to see how popular the interactive exhibition became. Now we are pleased to announce the continuing journey of Kontrapunkt Type, by taking the exhibit on the road: next stop, kyoto ddd gallery.</p>
                                </Paragraphs>
                                <Paragraphs className="is-lang-ja font-ja">
                                    <p>2019年に銀座のggg ギャラリーで開催されたKontrapunkt typeは様々なインターラクティブな手法が人気を博し、2020年には京都dddギャラリーでの展示開催となりました。</p>
                                </Paragraphs>
                            </ScrollReveal>
                        </div>
                    </Section>

                    <Section className="w-vw4/12 display:w-vw3/12 mr-32 grid-info">
                        <div className="container relative grid-into-2-row pt-20">
                            <ScrollReveal>
                                <Paragraphs className="is-lang-en">
                                    <p>Unfortunately, the current COVID-19 pandemic has created a new reality for everyone and globally people are increasingly being encouraged to stay at home. With this virtual exhibition, we wish to invite people everywhere, from Kyoto to New York, to join the experience of Kontrapunkt Type. All from the shelter of their home. Please enjoy!</p>
                                </Paragraphs>

                                <Paragraphs className="is-lang-ja font-ja">
                                    <p>COVID-19の世界的な流行により、自宅で過ごす時間が長くなっている日々がより有意義で充実感のある時間になるよう、また世界中どこからでも鑑賞して楽しんでいただけることを願っています。</p>
                                </Paragraphs>
                            </ScrollReveal>
                        </div>
                    </Section>

                    <Section className="w-vw6/12 display:w-vw5/12 mr-32 grid-info">
                        <ScrollReveal className="w-full grid-into-2-row -mt-12">
                            <Gallery />
                        </ScrollReveal>
                    </Section>

                    <Section className="w-vw4/12 display:w-vw3/12 mr-8 grid-info">
                        <div className="container relative grid-into-2-row pt-20">
                            <ScrollReveal>
                                <h2 className="absolute -mt-32 text-xl leading-snug">A bespoke outlook</h2>
                                <Paragraphs className="is-lang-en">
                                    <p>With its ability to cross borders and bridge cultures, a bespoke typeface is a perfect polyglot. A born globalist, it speaks multiple languages, but its voice and personality stay the same. It may shout or whisper, be informative or playful. Global, yet inherently belonging to its time, place and tribe. </p>
                                </Paragraphs>
                                <Paragraphs className="is-lang-ja font-ja">
                                    <p>「 Kontrapunkt Type」はそれぞれの書体が持つ意味を探求していくことで、その背景にあるブランドストーリーを知る事ができます。</p>
                                    <p>ブランド独自の書体は、多言語にマッチし、国境を越え、文化の架け橋となります。これは創り出された時から世界に通じ、多言語に適していますが、そのボイスや特徴は変わりません。叫ぶこともあれば囁くこともあり、実用的だったり、遊び心に満ちていたり。グローバルでありながら、本質的に、時・場所・族に特有のものです。</p>
                                </Paragraphs>
                            </ScrollReveal>
                        </div>
                    </Section>

                    <Section className="w-vw4/12 display:w-vw3/12 mr-8 grid-info">
                        <div className="container relative grid-into-2-row pt-20">
                            <ScrollReveal>
                                <Paragraphs className="is-lang-en">
                                    <p>We tell it in the design of the letters to connote a certain Nordic feeling, symbolise hop leaves, take cues from audio waves or an iconic logo. It's these details that convey not only the words they form, but the brand personality itself. We treat the letters as individual sculptures because every element of the alphabet bears with it so many narrative threads that make up the bigger story. </p>
                                </Paragraphs>
                                <Paragraphs className="is-lang-ja font-ja">
                                    <p>書体は、それぞれ独自のストーリーが込められたブランディングの要素の一つです。私たちはストーリーを文字のデザインで語ります。北欧特有の感覚を暗示し、ホップの葉をシンボルとして表したり、音の響きや象徴的なロゴからヒントを得たりするのです。書体が形作る言葉だけでなく、ブランドの特徴そのものも伝えるのは、こうしたディテールです。アルファベットのすべての要素が、さらなるストーリーを生み出すたくさんの語り口を持っているので、私たちはそれぞれの文字を独立したスカルプチュア（彫刻）として扱っています。</p>
                                </Paragraphs>
                            </ScrollReveal>
                        </div>
                    </Section>

                    <Section className="w-vw4/12 display:w-vw3/12 mr-8 grid-info">
                        <div className="container relative grid-into-2-row pt-20">
                            <ScrollReveal>
                                <Paragraphs className="is-lang-en">
                                    <p>To us, type design is a form of sculptural storytelling, and the story doesn't end here. We're humbled and beyond excited to have the opportunity to share our perspective on type with you. There is no script to follow, only intuition. The next chapter is up to you.</p>
                                </Paragraphs>
                                <Paragraphs className="is-lang-ja font-ja">
                                    <p>私たちにとって、タイプ（書体）デザインは彫刻のように物語る一つの形であり、しかもストーリーはここで終わるのではありません。書体に対する見方を皆様と共有できるこの機会を得られたことは大変光栄で、心から嬉しく思っております。筋書きなどありません。直感勝負。次は皆さんの番です。どうぞお楽しみください。</p>
                                </Paragraphs>
                            </ScrollReveal>
                        </div>
                    </Section>

                    <Section className="w-vw6/12 display:w-vw4/12 12 mx-48 flex items-center h-screen is-lang-en">
                        <div className="container relative py-32">
                            <ScrollReveal>
                                <p className="relative text-3xl leading-tight">
                                <span className="absolute -mt-12 block w-3 h-3 rounded-full bg-white"></span>
                                <span className="is-lang-en">The Kontrapunkt Type exhibition is about the form and meaning of letters and how they convey a story with their form.</span>
                                </p>
                            </ScrollReveal>
                        </div>
                    </Section>

                    <Section className="w-vw4/12 display:w-vw3/12 mr-8 grid-info">
                        <div className="container relative grid-into-2-row pt-20">
                            <ScrollReveal>
                                <h2 className="absolute -mt-32 text-xl leading-snug is-lang-en">A holistic approach</h2>
                                <h2 className="absolute -mt-32 text-xl leading-snug is-lang-ja font-ja">ホリスティック・アプローチ</h2>
                                <Paragraphs className="is-lang-en">
                                    <p>Kontrapunkt is an independent brand and design agency. We've been working with global and local brands for 35 years, and today we are more than 50 professionals working from offices in Copenhagen and Tokyo. </p>
                                </Paragraphs>
                                <Paragraphs className="is-lang-ja font-ja">
                                    <p>Kontrapunkt（コントラプンクト）デンマークの首都コペンハーゲンと東京に拠点を置く、北欧のリーディングデザインエージェンシー。35年以上に渡り、歴史と伝統ある大企業から、スタートアップの会社まで、世界中の多岐に渡る業種のブランディングを手がける。</p>
                                </Paragraphs>
                            </ScrollReveal>
                        </div>
                    </Section>

                    <Section className="w-vw4/12 display:w-vw3/12 mr-20 grid-info">
                        <div className="container relative grid-into-2-row pt-20">
                            <ScrollReveal>
                                <Paragraphs className="is-lang-en">
                                    <p>We work with everything from strategy to concept to user experience – adding a holistic design concept across all touchpoints.</p>
                                </Paragraphs>
                                <Paragraphs className="is-lang-ja font-ja">
                                    <p>戦略、グラフィック、空間、タイポグラフィ、デジタル等、各分野のプロフェッショナル約50名が在籍し、分野の垣根を超えたホリスティック・アプローチによるブランディングは、これまで様々な国際デザインアワードを受賞</p>
                                </Paragraphs>
                            </ScrollReveal>
                        </div>
                    </Section>

                    <Section className="relative w-vw6/12 display:w-vw4/12 grid-info display:pt-16">
                        <div className="absolute top-0 mt-12 left-0 -ml-px h-screen w-px bg-white opacity-25"></div>
                        <div className="container w-auto m-auto grid-into-2-row -mt-20">
                            <ScrollReveal>
                            <div className="mb-12 mt-8">
                                <h3 className="mb-1 text-gray">Opening hours</h3>
                                <div className="is-lang-en">
                                    {/* <p>kyoto ddd gallery is closed for the time being in order to prevent<br /> the spread of the COVID-19. <br />The reopening will be announced on <a className="underline hover:text-yellow transition duration-500 ease-in-out" href="http://www.dnp.co.jp/gallery/ddd_e/">our website</a></p> */}
                                    {/* <p className="mb-6">The exhibiton will be running from the 13th of April till<br /> the 13th of June 2020.</p> */}
                                    <p>Dates: June 9 (Tue) – October 3 (Sat), 2020<br />
                                    Hours: 11:00 – 19:00 (18:00 on Saturdays)<br />
                                    Closed Sundays, Mondays and holidays</p>
                                    <p className="mt-4">For more information please visit the gallery's <a href="http://www.dnp.co.jp/CGI/gallery/schedule/detail.cgi?l=2&t=2&seq=00000759" className="underline hover:text-yellow transition duration-500 ease-in-out" target="blank">website</a></p>
                                </div>

                                <div className="is-lang-ja font-ja">

                                <p>開館時間11:00－19:00 ※土曜日は18：00まで<br />
                                    日曜・月曜・祝日休館 入場無料
                                </p>
                                    <p className="mt-4">For more information please visit the gallery's <a href="http://www.dnp.co.jp/CGI/gallery/schedule/detail.cgi?l=1&t=2&seq=00000759" className="underline hover:text-yellow transition duration-500 ease-in-out" target="blank">website</a></p>
                                </div>
                            </div>

                            <div className="mb-12">
                                <h3 className="mb-1 text-gray">Visit</h3>
                                <div className="is-lang-en">
                                    <p>The exhibition is located in the heart of Kyoto.</p>
                                    <p><a href="https://goo.gl/maps/jHiiLib6jJiSiECn9" className="underline hover:text-yellow transition duration-500 ease-in-out" cursor="active">Find your directions here</a></p>
                                </div>
                                <div className="is-lang-ja font-ja">
                                    <p>〒616‒8533 京都市右京区太秦上刑部町10</p>
                                    <p>TEL: <a href="tel:075-871-1480" className="underline hover:text-yellow transition duration-500 ease-in-out" cursor="active">075-871-1480</a> FAX: <a href="tel:075-871-1267" className="underline hover:text-yellow transition duration-500 ease-in-out" cursor="active">075-871-1267</a></p>
                                </div>
                            </div>
                            <div>
                                <h3 className="mb-1 text-gray">Credits</h3>
                                <div>
                                    <p>Music written and produced by <a href="https://www.instagram.com/astrid_engberg/?hl=da" target="blank">Astrid Engberg</a></p>
                                </div>
                            </div>
                            </ScrollReveal>
                        </div>
                    </Section>
                    <Section className="relative w-vw6/12 display:w-vw4/12 display:pt-16 grid-info">
                        <div className="absolute top-0 mt-12 left-0 h-screen w-px bg-white opacity-25"></div>
                        <div className="container w-auto m-auto grid-into-2-row -mt-20">
                            <ScrollReveal>
                            <div className="mb-12 mt-8">
                                <h3 className="mb-1 text-gray">Presskit</h3>
                                <div className="is-lang-en">
                                    <p>Download the presskit containing images, logos, and the<br /> story about the exhibition.</p>
                                    <p><a href="https://www.dropbox.com/sh/m4ycyk8agd91p71/AAA-EGjZp5kM2MA5yNL1x0tua?dl=1" target="blank" className="underline hover:text-yellow transition duration-500 ease-in-out" cursor="active">Download presskit</a></p>
                                </div>

                                <div className="is-lang-ja font-ja">
                                    <p>展示情報、画像等含むプレスキット</p>
                                    <p><a href="https://www.dropbox.com/sh/m4ycyk8agd91p71/AAA-EGjZp5kM2MA5yNL1x0tua?dl=1" target="blank" className="underline hover:text-yellow transition duration-500 ease-in-out" cursor="active">Presskitダウンロード</a></p>

                                </div>
                            </div>
                            <div>
                                <h3 className="mb-1 text-gray">The next location</h3>
                                <div className="is-lang-en">
                                    <p>Are you interested in bringing the Kontrapunkt Type exhibiton to<br /> a gallery near you? Please reach out to us.</p>
                                    <p><a href="mailto:social@kontrapunkt.com" className="underline hover:text-yellow transition duration-500 ease-in-out" cursor="active">social@kontrapunkt.com</a></p>
                                </div>

                                <div className="is-lang-ja font-ja">
                                    <p>プレス関連、取材依頼、その他お問い合わせ先</p>
                                    <p><a href="mailto:tha@kontrapunkt.com" className="underline hover:text-yellow transition duration-500 ease-in-out" cursor="active">tha@kontrapunkt.com</a></p>
                                </div>

                                
                            </div>
                            </ScrollReveal>
                        </div>
                    </Section>

                </Sections>
            </ScrollWrapper>
            <Footer scrollTo={scrollTo} forwardedRef={{
                footer,
                scrollNudge,
            }} />
        </Wrapper>
    )
}

export default InfoDesktop;