
import React, { useEffect, useRef } from "react";
import { lerp } from '../utils';
import VirtualScroll from 'virtual-scroll';

export default ({ children }) => {

    const state = useRef({
        instance: null,
        vw: 9999,
        scroll : 0,
        targetScroll : 0,
        limit: 99999,
        inertia: 0.08,
        acf: null,
    });
    
    const frame = useRef(null);

    
    useEffect(() => {

        setTimeout(() => {
            onResize();
        });

        const scroll = new VirtualScroll({
            mouseMultiplier: navigator.platform.indexOf('Win') > -1 ? 1 : 0.4,
            useKeyboard: false,
            passive: true,
        }); 

        state.current.instance = scroll;

        scroll.on(onScroll);

        update();
        window.addEventListener('scrollTo', onScrollTo);
        window.addEventListener('resize', onResize);    
        window.addEventListener('lockScroll', lockScroll);    
        window.addEventListener('releaseScroll', releaseScroll);    
        
        return () => {

            scroll.off();
            scroll.destroy();
            window.removeEventListener('scrollTo', onScrollTo);
            window.removeEventListener('resize', onResize); 
            window.removeEventListener('lockScroll', lockScroll);    
            window.removeEventListener('releaseScroll', releaseScroll);    
            cancelAnimationFrame(state.current.acf);
        }
    },[]);

    const lockScroll = () => {
        
        state.current.instance.off(); 
        state.current.targetScroll = state.current.scroll;
    }

    const releaseScroll = () => {
        
        state.current.instance.on(onScroll); 
    }


    const onResize = () => {
        
        state.current.vw = window.innerWidth;
        state.current.limit = frame.current.clientWidth - state.current.vw;

        checkBounds();
    }

    const checkBounds = () => {
        if(state.current.targetScroll < 0) {
            state.current.targetScroll = 0;
        } 

        if(state.current.targetScroll >= state.current.limit) {
            state.current.targetScroll = state.current.limit;
        }
    }

    const onScrollTo = (e) => {
        const {target} = e.detail;

        state.current.targetScroll = target;
    }

    const update = () => {
    
        state.current.scroll = lerp(state.current.scroll,state.current.targetScroll, state.current.inertia);

        window.dispatchEvent(new CustomEvent("onUpdate", {
            detail: {
                scroll: state.current.scroll,
                velocity: state.current.targetScroll - state.current.scroll,
            }
        }));

  
        frame.current.style.transform = `translate3d(${state.current.scroll * -1}px,0,0)`;

       state.current.acf = requestAnimationFrame(update);
    }
    const onScroll = (e) => {
        
        state.current.targetScroll -= e.deltaY ?  e.deltaY  : e.deltaX ;

        checkBounds();
    }

    return (
        <div ref={frame} className="float-left">
            {children}
        </div>
    )
};

