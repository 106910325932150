import React from "react";
import InfoMobile from './infoMobile';
import InfoDesktop from './infoDesktop';


const Info = ({transitionStatus}) => {
    return (
        <>
            <InfoMobile transitionStatus={transitionStatus} />
            <InfoDesktop transitionStatus={transitionStatus} />
        </>    
    )
}

export default Info;