import React, {useEffect, useRef} from "react";
import {clamp} from '../utils';

const Parallax = ({children, speed,clampScroll, center }) => {

    const elm = useRef(null);
    const cache = useRef({
        offset: 0,
        w: 0,
    })

    useEffect(() => {
        
        onResize();
        window.addEventListener('onUpdate', onUpdate);
        window.addEventListener('resize', onResize);

        return() => {
            window.removeEventListener('onUpdate', onUpdate);
            window.removeEventListener('resize', onResize);
        }
    },[]);

    const onResize = () => {

        cache.current.offset = center ? elm.current.offsetLeft + elm.current.clientWidth/2 : elm.current.offsetLeft;
        cache.current.w = window.innerWidth;
    }

    const onUpdate = (e) => {
        
        const {scroll} = e.detail;
  
        if(center) {
            elm.current.style.transform = `translate3d(${getScrollPosition(scroll)}px,0,0)`;
        } else {
            elm.current.style.transform = `translate3d(${getScrollPosition(scroll)}px,0,0)`;
        }
    }

    const getScrollPosition = (scroll) => {

        const scrollCenter = (scroll + cache.current.w/2 - cache.current.offset);
        const scrollLeft = (scroll);
        var position;        

        if(center) {
            position = scrollCenter/speed;
        } else {
            position = scrollLeft/speed;
        }
        if(clampScroll) {
            position = clamp(position, clampScroll.min,clampScroll.max);
        }

        return position;
    }
    return (
        <div ref={elm}>{children}</div>
    )
}

export default Parallax;