import React, {useEffect, useRef} from "react";
import gsap from 'gsap';
import Gallery from '../../components/gallery';
import {isLoaded} from '../../utils';

const Hero = () => {
    return (
        <div className="pt-24 mb-24 min-h-screen">
            <div className="relative">
                <div className="vertical-text whitespace-no-wrap">
                    <h1 className="text-3xl md:text-4xl leading-none ml-12"><span className="mb-12">A</span> Solo</h1>
                    <h1 className="text-3xl md:text-4xl leading-none ml-12">Exhibition</h1>
                    <h1 className="text-3xl md:text-4xl leading-none"><span className="mb-6">By</span> Kontrapunkt</h1>
                </div>
                <span className="absolute bottom-0 ml-24 pl-4 text-sm leading-snug">
                    <p className="mb-2">Kontrapunkt type</p>
                    <p className="text-gray">Exhibition in collaboration with <br />kyoto ddd gallery</p>
                </span>
            </div>
        </div>
    )
}

const Section = ({children, className}) => {
    return (
        <div className={`my-16 ${className}`}>{children}</div>
    )
}

const InfoMobile = ({transitionStatus}) => {

    const wrapper = useRef(null);

    useEffect(() => {
        revealSetup();
    },[]);

    useEffect(() => {
    
       
        if(!isLoaded()) {
            window.addEventListener('loadingDone', revealScene);
        }
        
        if(isLoaded() && transitionStatus == 'entered') {
            revealScene();
        }

        if(transitionStatus == 'exiting') {
            exitScene();
        }
    },[transitionStatus]);


    const revealSetup = () => {
        
        gsap.set(wrapper.current, {
            opacity:0,
        });
    
    }
    const revealScene = () => {
        

        gsap.to(wrapper.current, {
            opacity:1,
            duration:1,
        });

        window.removeEventListener('loadingDone', revealScene);
    }

    const exitScene = () => {
        
        gsap.to(wrapper.current, {
            opacity:0,
            duration:1,
        });
    }

    return (
        <div ref={wrapper} className="fixed top-0 left-0 w-full h-full overflow-y-scroll scrolling-touch lg:hidden">
            <div className="container">
               <Hero />
               <Section>
                    <h2 className="text-md leading-snug mb-8 is-lang-en">A virtual<br /> exhibition is born</h2>
                    <h2 className="text-md leading-snug mb-8 is-lang-ja font-ja">バーチャル展示の誕生</h2>

                    <p className="text-gray is-lang-en">In 2019 we opened the doors to Kontrapunkt Type at Ginza Graphic Gallery in Tokyo. With more than 150 visitors each day, we were thrilled to see how popular the interactive exhibition became. Now we are pleased to announce the continuing journey of Kontrapunkt Type, by taking the exhibit on the road: next stop, kyoto ddd gallery.</p>
                    <p className="text-gray is-lang-ja font-ja">2019年に銀座のggg ギャラリーで開催されたKontrapunkt typeは様々なインターラクティブな手法が人気を博し、2020年には京都dddギャラリーでの展示開催となりました。</p>
               </Section>
               <Section>
                    <p className="text-gray is-lang-en">Unfortunately, the current COVID-19 pandemic has created a new reality for everyone and globally people are increasingly being encouraged to stay at home. With this virtual exhibition, we wish to invite people everywhere, from Kyoto to New York, to join the experience of Kontrapunkt Type. All from the shelter of their home. Please enjoy!</p>
                    <p className="text-gray is-lang-ja font-ja">COVID-19の世界的な流行により、自宅で過ごす時間が長くなっている日々がより有意義で充実感のある時間になるよう、また世界中どこからでも鑑賞して楽しんでいただけることを願っています。</p>
               </Section>
               
               <Section>
                    <Gallery />
               </Section>

               <Section>
                    <h2 className="text-md leading-snug mb-8">A bespoke outlook</h2>
                    <p className="text-gray is-lang-en">With its ability to cross borders and bridge cultures, a bespoke typeface is a perfect polyglot. A born globalist, it speaks multiple languages, but its voice and personality stay the same. It may shout or whisper, be informative or playful. Global, yet inherently belonging to its time, place and tribe. </p>
                    <p className="text-gray is-lang-ja font-ja">「 Kontrapunkt Type」はそれぞれの書体が持つ意味を探求していくことで、その背景にあるブランドストーリーを知る事ができます。</p>
                    <p className="text-gray is-lang-ja font-ja">ブランド独自の書体は、多言語にマッチし、国境を越え、文化の架け橋となります。これは創り出された時から世界に通じ、多言語に適していますが、そのボイスや特徴は変わりません。叫ぶこともあれば囁くこともあり、実用的だったり、遊び心に満ちていたり。グローバルでありながら、本質的に、時・場所・族に特有のものです。</p>
                    
               </Section>
               <Section>
                    <p className="text-gray is-lang-en">We tell it in the design of the letters to connote a certain Nordic feeling, symbolise hop leaves, take cues from audio waves or an iconic logo. It's these details that convey not only the words they form, but the brand personality itself. We treat the letters as individual sculptures because every element of the alphabet bears with it so many narrative threads that make up the bigger story. </p>
                    <p className="text-gray is-lang-ja font-ja">書体は、それぞれ独自のストーリーが込められたブランディングの要素の一つです。私たちはストーリーを文字のデザインで語ります。北欧特有の感覚を暗示し、ホップの葉をシンボルとして表したり、音の響きや象徴的なロゴからヒントを得たりするのです。書体が形作る言葉だけでなく、ブランドの特徴そのものも伝えるのは、こうしたディテールです。アルファベットのすべての要素が、さらなるストーリーを生み出すたくさんの語り口を持っているので、私たちはそれぞれの文字を独立したスカルプチュア（彫刻）として扱っています。</p>
               </Section>
               <Section>
                    <p className="text-gray is-lang-en">To us, type design is a form of sculptural storytelling, and the story doesn't end here. We're humbled and beyond excited to have the opportunity to share our perspective on type with you. There is no script to follow, only intuition. The next chapter is up to you.</p>
                    <p className="text-gray is-lang-ja font-ja">私たちにとって、タイプ（書体）デザインは彫刻のように物語る一つの形であり、しかもストーリーはここで終わるのではありません。書体に対する見方を皆様と共有できるこの機会を得られたことは大変光栄で、心から嬉しく思っております。筋書きなどありません。直感勝負。次は皆さんの番です。どうぞお楽しみください。</p>
               </Section>
               <Section className="is-lang-en">
                    <p className="relative text-2xl leading-tight pt-20 pb-8">
                    <span className="absolute -mt-12 block w-3 h-3 rounded-full bg-white"></span>
                        <span className="is-lang-en">The Kontrapunkt Type exhibition is about the form and meaning of letters and how they convey a story with their form.</span>
                        <span className="is-lang-ja font-ja">「Kontrapunkt Type」はそれぞれの書体が持つ意味を探求していくことで、その背景にあるブランドストーリーを知る事ができます。</span>
                    </p>
                </Section>

                <Section>
                    <h2 className="text-md leading-snug mb-8 is-lang-en">A holistic approach</h2>
                    <h2 className="text-md leading-snug mb-8 is-lang-ja font-ja">ホリスティック・アプローチ</h2>
                    
                    <p className="text-gray is-lang-en">Kontrapunkt is an independent brand and design agency. We've been working with global and local brands for 35 years, and today we are more than 50 professionals working from offices in Copenhagen and Tokyo. </p>
                    <p className="text-gray is-lang-ja font-ja">Kontrapunkt（コントラプンクト）デンマークの首都コペンハーゲンと東京に拠点を置く、北欧のリーディングデザインエージェンシー。35年以上に渡り、歴史と伝統ある大企業から、スタートアップの会社まで、世界中の多岐に渡る業種のブランディングを手がける。</p>
               </Section>

               <Section>    
                    <p className="text-gray is-lang-en">We work with everything from strategy to concept to user experience – adding a holistic design concept across all touchpoints.</p>
                    <p className="text-gray s-lang-ja font-ja">戦略、グラフィック、空間、タイポグラフィ、デジタル等、各分野のプロフェッショナル約50名が在籍し、分野の垣根を超えたホリスティック・アプローチによるブランディングは、これまで様々な国際デザインアワードを受賞。</p>
               </Section>

               <Section>
                <div className="border-t border-white -mx-5 mt-24 mb-24 opacity-25"></div>

                <div className="mb-12">
                    <h3 className="mb-1 text-gray">Presskit</h3>
                    <div className="is-lang-en">
                        <p>Download the presskit containing images, logos, and the<br /> story about the exhibition.</p>
                        <p><a href="https://www.dropbox.com/sh/m4ycyk8agd91p71/AAA-EGjZp5kM2MA5yNL1x0tua?dl=1" target="blank" className="underline hover:text-yellow transition duration-500 ease-in-out" cursor="active">Download presskit</a></p>
                    </div>

                    <div className="is-lang-ja font-ja">
                        <p>展示情報、画像等含むプレスキット</p>
                        <p><a href="https://www.dropbox.com/sh/m4ycyk8agd91p71/AAA-EGjZp5kM2MA5yNL1x0tua?dl=1" target="blank" className="underline hover:text-yellow transition duration-500 ease-in-out" cursor="active">Presskitダウンロード</a></p>

                    </div>
                </div>

                <div className="mb-12">
                    <h3 className="mb-1 text-gray">Opening hours</h3>
                        <div className="is-lang-en">
                        {/* <p>kyoto ddd gallery is closed for the time being in order to prevent<br /> the spread of the COVID-19. <br />The reopening will be announced on <a className="underline hover:text-yellow transition duration-500 ease-in-out" href="http://www.dnp.co.jp/gallery/ddd_e/">our website</a></p> */}
                        {/* <p className="mb-6">The exhibiton will be running from the 13th of April till<br /> the 13th of June 2020.</p> */}
                        <p>Dates: June 9 (Tue) – October 3 (Sat), 2020<br />
                        Hours: 11:00 – 19:00 (18:00 on Saturdays)<br />
                        Closed Sundays, Mondays and holidays</p>
                        <p className="mt-4">For more information please visit the gallery's <a href="http://www.dnp.co.jp/CGI/gallery/schedule/detail.cgi?l=2&t=2&seq=00000759" className="underline hover:text-yellow transition duration-500 ease-in-out" target="blank">website</a></p>
                    </div>

                    <div className="is-lang-ja font-ja">
                    <p>開館時間11:00－19:00 ※土曜日は18：00まで<br />
                        日曜・月曜・祝日休館 入場無料
                    </p>
                        <p className="mt-4">For more information please visit the gallery's <a href="http://www.dnp.co.jp/CGI/gallery/schedule/detail.cgi?l=1&t=2&seq=00000759" className="underline hover:text-yellow transition duration-500 ease-in-out" target="blank">website</a></p>
                    </div>
                </div>

                <div className="mb-12">
                    <h3 className="mb-1 text-gray">Visit</h3>
                    <div className="is-lang-en">
                        <p>The exhibition is located in the heart of Kyoto.</p>
                        <p><a href="https://goo.gl/maps/jHiiLib6jJiSiECn9" className="underline hover:text-yellow transition duration-500 ease-in-out" cursor="active">Find your directions here</a></p>
                    </div>
                    <div className="is-lang-ja font-ja">
                        <p>〒616‒8533 京都市右京区太秦上刑部町10</p>
                        <p>TEL: <a href="tel:075-871-1480" className="underline hover:text-yellow transition duration-500 ease-in-out" cursor="active">075-871-1480</a> FAX: <a href="tel:075-871-1267" className="underline hover:text-yellow transition duration-500 ease-in-out" cursor="active">075-871-1267</a></p>
                    </div>
                </div>

                <div className="mb-12">
                    <h3 className="mb-1 text-gray">The next location</h3>
                    <div className="is-lang-en">
                        <p>Are you interested in bringing the Kontrapunkt Type exhibiton to<br /> a gallery near you? Please reach out to us.</p>
                        <p><a href="mailto:social@kontrapunkt.com" className="underline hover:text-yellow transition duration-500 ease-in-out" cursor="active">social@kontrapunkt.com</a></p>
                    </div>

                    <div className="is-lang-ja font-ja">
                        <p>プレス関連、取材依頼、その他お問い合わせ先</p>
                        <p><a href="mailto:tha@kontrapunkt.com" className="underline hover:text-yellow transition duration-500 ease-in-out" cursor="active">tha@kontrapunkt.com</a></p>
                    </div>
                </div>

                <div>
                    <h3 className="mb-1 text-gray">Credits</h3>
                    <div>
                        <p>Music written and produced<br /> by <a href="https://www.instagram.com/astrid_engberg/?hl=da" target="blank">Astrid Engberg</a></p>
                    </div>
                </div>
               </Section>
            </div>
        </div>
    )
}

export default InfoMobile;