import React, {useEffect, useRef} from "react"
import gsap from 'gsap';
const ScrollReveal = ({children, className}) => {


    const root = useRef(null);
    const store = useRef({offset:0, w: null, currentScroll: 0});
    useEffect(() => {
        
        setup();
        resize();
        window.addEventListener('onUpdate', onUpdate);
        window.addEventListener('resize', resize);
    
        return () => {
            window.removeEventListener('onUpdate', onUpdate);
            window.removeEventListener('resize', resize);
        }
    },[]);

    const setup = () => {
        gsap.to(root.current.children, {
            opacity:0,
        });
    }

    const onUpdate = (e) => {
        const {scroll} = e.detail;
        store.current.currentScroll = scroll;
        if(scroll + store.current.w >= store.current.offset) {
            reveal();
            window.removeEventListener('onUpdate', onUpdate);
            window.removeEventListener('resize', resize);
        }
    }

    const reveal = () => {
        
        gsap.to(root.current.children, {
            opacity:1,
            duration:0.8,
            stagger: {
                amount:0.1,
            }
        });
    }

    const resize = () => {

        store.current.w = window.innerWidth;
        store.current.offset = root.current.getBoundingClientRect().left + store.current.currentScroll;
    }
    
    return (
        <div ref={root} className={className}>{children}</div>
    )
}

export default ScrollReveal;