import React, {useEffect, useRef, useState } from "react";
import Flickity from 'flickity';
import {pad} from '../utils';

import img1 from '../images/gallery/slider1.jpg';
import img2 from '../images/gallery/slider2.jpg';
import img3 from '../images/gallery/slider3.jpg';
import img4 from '../images/gallery/slider4.jpg';
import img5 from '../images/gallery/slider5.jpg';

const ImageSlide = ({src}) => {
    return (
        <div className="relative w-full h-0" style={{paddingBottom: 'calc(9/16 * 100%)'}}>
            <img className="absolute top-0 left-0 w-full h-full object-cover object-center"  src={src} alt=""/>
        </div>
    )
}

const Gallery = () => {

    const flickity = useRef(null);
    const slider = useRef(null);
    const [active, setActive] = useState(0);

    useEffect(() => {

        flickity.current =  new Flickity( slider.current, {
            cellAlign: 'left',
            percentPosition: false,
            wrapAround: true,
            pageDots: false,
            prevNextButtons: false,
          });

          flickity.current.on( 'change', onchange);

          flickity.current.on( 'dragMove', function( event, pointer, moveVector ) {
            
            window.dispatchEvent(new CustomEvent("mouseDrag", {
                detail: {
                    event
                }
            }));
        });

    });

    const onchange = (index) => {
        setActive(index);
    }

    const prev = () => {
        if(!flickity.current) return;
        
        flickity.current.previous();
    }

    const next = () => {
        if(!flickity.current) return;
        
        flickity.current.next();
    }

    return (
        <div className="relative w-full pb-8">
            <div className="w-full" ref={slider} cursor="drag">
                <ImageSlide src={img1} />
                <ImageSlide src={img2} />
                <ImageSlide src={img3} />
                <ImageSlide src={img4} />
                <ImageSlide src={img5} />
            </div>
            <div className="absolute w-full flex justify-center lg:w-auto bottom-0 right-0 flex items-center text-xs">
                <span className="arrow-left mr-6" onClick={prev} cursor="active"></span>
                
                    <span className="w-4">{pad(active+1,2)}</span>
                    <span className="w-px h-px p-px bg-white rounded-full mx-1 mt-px"></span>
                    <span className="w-4 text-gray">{pad(5,2)}</span>
    
                <span className="arrow-right ml-6" onClick={next} cursor="active"></span>
            </div>
        </div>
    )
}

export default Gallery;